import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"

import GreenHeading from "./heading"
import { ReserveNow, LearnMore } from "./hooks/useLinks"
import device, { sizes } from "./device"

const Wrapper = styled.div`
  .image-wrapper {
    margin-top: -12rem;
    position: relative;
    z-index: -10;
    ${device.small`margin-top: 0rem; margin-bottom: 5rem;`}
    ${device.large`margin-top: -15rem;`}
  }
`

const Text = styled.div`
  position: relative;
  width: 56%;
  margin-left: auto;
  margin-top: -5rem;
  ${device.large`margin-top: -10rem;`}
  ${device.medium`width: 70%;`}
  ${device.small`width: var(--spread); margin-right: auto; margin-top: 0;`}
  h2 {
    font-size: 2rem;
    line-height: 1;
    ${device.small`font-size: 1.5rem; text-align: center;`}
  }
  h3 {
    margin: 1rem 0;
    color: var(--grey);
    ${device.small`text-align: center;`}
  }
  p {
    margin: 0.5rem 0 2rem 0;
    font-size: 0.95rem;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    width: 70%;
    max-width: 800px;
    ${device.small`width: 100%; margin: 2rem 0;`}
  }
  .links {
    font-size: 0.7rem;
    ${device.small`display: none;`}
    a {
      margin-right: 3rem;
    }
    .learn-more {
      font-family: "Montserrat", sans-serif;
      font-weight: 700;
      color: var(--black);
      padding: calc(0.5rem - 1px) 1.5rem;
    }
  }
`

const Welcome = ({ data }) => {
  const imgData = useStaticQuery(graphql`
    query {
      desktopBg: file(relativePath: { eq: "welcome/bg-desktop.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      mobileBg: file(relativePath: { eq: "welcome/bg-mobile.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 450, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)

  const sources = [
    imgData.mobileBg.childImageSharp.fluid,
    {
      ...imgData.desktopBg.childImageSharp.fluid,
      media: `(min-width: ${sizes.small}px)`,
    },
  ]

  return (
    <Wrapper>
      <Text>
        <h2>
          <GreenHeading text={data.h3} />
          <br />
          {data.h2}
        </h2>
        <h3>{data.h1}</h3>
        <p>{data.text}</p>
        <div className="links">
          <LearnMore className="learn-more" />
          <ReserveNow />
        </div>
      </Text>
      <div className="image-wrapper">
        <Img fluid={sources} />
      </div>
    </Wrapper>
  )
}

export default Welcome
