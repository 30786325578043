import React from "react"
import { graphql } from "gatsby"
import "intersection-observer" // optional polyfill
import { useInView } from "react-intersection-observer"
import Layout from "../components/layout"
import GreenHeading from "../components/heading"
import { sizes } from "../components/device"
import useViewport from "../components/hooks/useViewport"
import Hero from "../components/hero/hero"
import Welcome from "../components/welcome"
import loadable from "@loadable/component"
import { Helmet } from "react-helmet"
import { GeneralSchema } from "@ashleynexvelsolutions/general-schema"
import { PageSpecificSchema } from "@ashleynexvelsolutions/page-specific-schema"
import {
  GeneralSchemaFunction,
  PageSpecificSchemaFunction,
} from "../DataImports"
import { useTravelDestinationsQuery } from "../components/hooks/use-travel-destinations-query"

const Services = loadable(() => import("../components/services/services"))
const Fleet = loadable(() => import("../components/fleet/fleet"))
const TravelDestinations = loadable(() =>
  import("../components/TravelDestinations.js")
)
const Reasons = loadable(() => import("../components/reasons/reasons"))
const Reviews = loadable(() => import("../components/reviews/reviews"))
const Download = loadable(() => import("../components/download"))
const Locations = loadable(() => import("../components/locations/locations"))

const IndexPage = ({ data }) => {
  const image =
    data.wpgraphql.page.seo !== null &&
    data.wpgraphql.page.seo.opengraphImage !== null
      ? data.site.siteMetadata.siteUrl +
        data.wpgraphql.page.seo.opengraphImage.imageFile.childImageSharp.fixed
          .src
      : "/blank.jpg"

  const isMobile = useViewport() <= sizes.small

  const [containerRef, containerInView] = useInView({
    triggerOnce: true,
    threshold: isMobile ? 0.3 : 0,
  })

  const acf = data.wordpressPage.acf
  const travelDestinations = useTravelDestinationsQuery()
  return (
    <>
      <GeneralSchema {...GeneralSchemaFunction()} />
      <PageSpecificSchema {...PageSpecificSchemaFunction(data)} />

      <Layout
        downloadLinks={{
          appStore: acf.app_store_link,
          googlePlay: acf.google_play_link,
        }}
      >
        <Helmet>
          <title>{`${data.site.siteMetadata.title} | ${data.wpgraphql.page.seo.title}`}</title>
          <meta
            name="description"
            content={data.wpgraphql.page.seo.opengraphDescription}
          />
          <meta
            property="og:url"
            content={data.wpgraphql.page.seo.opengraphUrl}
          />
          <meta
            property="og:site_name"
            content={data.wpgraphql.page.seo.opengraphSiteName}
          />
          <meta property="og:title" content={data.wpgraphql.page.seo.title} />
          <meta
            property="og:description"
            content={data.wpgraphql.page.seo.opengraphDescription}
          />
          <meta name="twitter:card" content="summary_large_image" />
          {/* Twitter */}
          <meta
            name="twitter:title"
            content={data.wpgraphql.page.seo.twitterTitle}
          />
          <meta
            name="twitter:description"
            content={data.wpgraphql.page.seo.twitterDescription}
          />

          <meta name="geo.region" content="US-PA" />
          <meta name="geo.placename" content="King of Prussia" />
          <meta name="geo.position" content="40.091710;-75.346160" />
          <meta name="ICBM" content="40.091710, -75.346160" />
        </Helmet>
        <Hero
          bannerData={{
            desktop: acf.banner_desktop,
            mobile: acf.banner_mobile,
          }}
          cars={true}
        >
          <h1 className="heading">
            A <GreenHeading size="6rem" text="Smart" animate={false} /> way
            <br /> to ride
          </h1>
        </Hero>

        <div ref={containerRef}>
          <Welcome
            data={{
              text: acf.welcome_text,
              h1: acf.welcome_h1,
              h2: acf.welcome_h2,
              h3: acf.welcome_h3,
            }}
          />
        </div>
        {containerInView ? (
          <>
            <Services />
            <Fleet />
            <TravelDestinations data={travelDestinations} />
            <Reasons />
            <Reviews />
            <Download text={acf.download_text} />
            <Locations text={acf.locations_text} />
          </>
        ) : (
          <div style={{ height: "200vh" }} />
        )}
      </Layout>
    </>
  )
}

export const query = graphql`
  {
    wordpressPage(title: { eq: "Home" }) {
      title
      acf {
        banner_desktop {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 1500, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        banner_mobile {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        client_reviews_text
        download_text
        welcome_text
        welcome_h1
        welcome_h2
        welcome_h3
        locations_text
      }
    }
    wpgraphql {
      page(id: "54", idType: DATABASE_ID) {
        seo {
          title
          opengraphDescription
          opengraphUrl
          opengraphSiteName
          twitterTitle
          twitterDescription
          opengraphImage {
            sourceUrl
            imageFile {
              childImageSharp {
                fixed {
                  src
                }
              }
            }
          }
        }
        nexvelschemapagesposts {
          videos {
            title
            url
            description
            thumbnailImage {
              sourceUrl
              imageFile {
                childImageSharp {
                  fixed {
                    src
                  }
                }
              }
            }
            uploadDate
          }
          questionsAndAnswers {
            question
            answer
          }
          maps {
            mapUrl
          }
          digitaldocuments {
            title
          }
          images {
            image {
              sourceUrl
              imageFile {
                childImageSharp {
                  fixed {
                    src
                  }
                }
              }
              date
              description
              title
            }
          }
          areaServedLandingPageSchema
          serviceSchema {
            serviceNameSchema
            linkToRelevantServicePageSchema
          }
          keywordsSchema {
            keywordSchema
          }
          localAwardsSchema {
            localAwardSchema
          }
          latitudeSchema
          longitudeSchema
          relevantExternalPagesSchema {
            relevantExternalPageSchema
          }
        }
      }
    }
    wordpressAcfOptions {
      options {
        cities {
          city
        }
        services {
          service
          link_to_relevant_page_on_your_website
        }
      }
    }
    site {
      siteMetadata {
        title
        description
        siteUrl
      }
    }
  }
`

export default IndexPage
